import { ClipLoader } from "react-spinners"
import { useGetAnalysisOverview } from "../../../core/services/react-query/analysis"
import { getMainTheme } from "../../../core/utils/theme"
import AnalysisDateSelect from "./AnalysisDateSelect"
import { useMemo, useState } from "react"
import Text from "../../../core/utils/Text"
import { formatNumber } from "../../../core/utils/common"


const dates = [
    'today', 'this-week', 'this-month',
    'this-year', 'custom'
]

const TransactionBalances = ({ flow }) => {

    const [date, setDate] = useState('today')
    const [customDates, setCustomDates] = useState([])

    const params = useMemo(() => {
        const from = new Date()
        from.setUTCHours(0, 0, 0, 0)
        const to = new Date()
        switch (date) {
            case 'today':
                return { dateFrom: from.toISOString(), dateTo: to.toISOString() }
            case 'this-week':
                const daysAfterWeek = to.getDay() + 1
                from.setDate(from.getDate() - daysAfterWeek)
                return { dateFrom: from.toISOString(), dateTo: to.toISOString() }
            case 'this-month':
                from.setDate(1)
                return { dateFrom: from.toISOString(), dateTo: to.toISOString() }
            case 'this-year':
                from.setMonth(0)
                from.setDate(1)
                return { dateFrom: from.toISOString(), dateTo: to.toISOString() }
            default:
                break
        }

        if (customDates?.length > 1) {
            return { dateFrom: (new Date(customDates[0])).toISOString(), dateTo: (new Date(customDates[1])).toISOString() }
        }


    }, [date, customDates])

    const { data: coinData, isLoading: coinDataLoading } = useGetAnalysisOverview({ params, flow, type: 'coining' })
    const { data: bankData, isLoading: bankDataLoading } = useGetAnalysisOverview({ params, flow, type: 'banking' })

    return (
        <div>
            <AnalysisDateSelect
                value={date}
                onChange={setDate}
                options={dates}
                customDates={customDates}
                onCustomChange={setCustomDates}
            />
            <div className={`
            rounded-md bg-gray-100 dark:bg-mainBg py-3 px-5 shadow-md w-full
            border-[1px] border-slate-500 border-opacity-30 relative h-[120px]
                `}>
                {(coinDataLoading || bankDataLoading) ?
                    <div className={'absolute left-0 top-0 w-full h-full flex items-center justify-center'}>
                        <ClipLoader color={getMainTheme().active} size={20} />
                    </div>
                    :
                    null
                }

                <div className={'overflow-x-auto h-full w-full flex items-center gap-5'}>
                    {bankData?.length ?
                        <Item
                            coin={{
                                ...bankData[0],
                                coin: 'irt'
                            }}
                            flow={flow}
                        />
                        : null}


                    {coinData?.map(coin => {

                        return (
                            <Item
                                coin={coin}
                                flow={flow}
                                key={coin.coin}
                            />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}


const Item = ({ coin, flow, ...rest }) => {
    return (
        <div
            {...rest}
            className={`rounded-lg bg-white dark:bg-secondaryBg
                    h-[82px] w-[200px] shadow flex items-center justify-center
                    dark:text-gray-300 px-5 text-sm
                `}
        >
            <div className={'flex w-full flex-col items-center gap-2'}>
                <div className={'flex items-center w-full justify-between'}>
                    <div className={'flex items-center gap-2'}>
                        <div className={'dark:bg-gray-300 bg-gray-700 rounded w-[12px] h-[12px]'} />
                        <span className={'mt-1'}>{coin.coin.toUpperCase()}</span>
                    </div>
                    <div className={'px-6 rounded dark:bg-gray-200 bg-slate-700'}>
                        <Text className={'text-xs dark:text-slate-700 text-gray-200'} tid={flow} />
                    </div>
                </div>
                <span className={'text-lg'}>{formatNumber(coin.amount, { type: coin.coin })}</span>
            </div>
        </div>
    )
}

export default TransactionBalances