import FilterView from "./FilterView";
import {useEffect} from "react";
import {useFilterContext} from "../../../core/contexts/filter";



const FilterLayout = (props) => {

    const { setCache } = useFilterContext()
    useEffect(() => {
        setCache(props.cache)
    }, [props.cache])


    return (
        <>
            <FilterView
                {...props}
            />
            {props.children}
            
        </>

    )
}

export default FilterLayout
