import { useContext, createContext, useState, useEffect } from "react";
import { loadPersistedData, persistData } from "../utils/persistor";
import i18next from "i18next";
import cookieService from "../services/cookie";
import { MOBILE_SIZE, TABLET_SIZE } from "../constants/common";
import { useWindowSize } from "../hooks/common/useWindowSize";



const context = createContext({
    main: {
        theme: "dark", setTheme: () => { },
        lang: "fa", setLang: () => { }
    },
    profile: {
        token: null, setToken: () => { }
    }
})

export const useMainContext = () => {
    return useContext(context)
}

const MainContextProvider = ({ children }) => {

    const { width } = useWindowSize()
    const [theme, setTheme] = useState(loadPersistedData('theme') ? loadPersistedData('theme') : "dark")
    const [lang, setLang] = useState(loadPersistedData('lang') ? loadPersistedData('lang') : "fa")
    const [token, _setToken] = useState(cookieService.get('token'))
    const [sidebar, setSidebar] = useState('desktop')
    const [orderTab, _setOrderTab] = useState(loadPersistedData('orderTab') ? loadPersistedData('orderTab') : 1)
    const [affiliateTab, _setAffiliateTab] = useState(loadPersistedData('affiliateTab') ? loadPersistedData('affiliateTab') : 1)


    useEffect(() => {
        setSidebar(width < MOBILE_SIZE ? 'mobile' : (
            width < TABLET_SIZE ? 'tablet'
                : 'desktop'
        ))
    }, [width]);


    useEffect(() => {
        persistData('theme', theme)
        if (theme === 'dark') document.documentElement.classList.add('dark');
        else document.documentElement.classList.remove('dark');
    }, [theme])

    useEffect(() => {
        i18next.changeLanguage(lang)
        persistData('lang', lang)
    }, [lang])


    const setToken = (value) => {
        cookieService.set('token', value, {})
        _setToken(value)
    }

    const setOrderTab = (idx) => {
        persistData('orderTab', idx)
        _setOrderTab(idx)
    }

    const setAffiliateTab = (idx) => {
        persistData('affiliateTab', idx)
        _setAffiliateTab(idx)
    }

    const onSidebarChange = () => {
        setSidebar(state => {
            if (state === 'desktop') {
                return width < MOBILE_SIZE ? 'mobile' : 'tablet'
            }
            return 'desktop'
        })

    }

    return (
        <context.Provider value={{
            theme, setTheme,
            lang, setLang,
            token, setToken,
            sidebar, onSidebarChange,
            orderTab, setOrderTab,
            affiliateTab, setAffiliateTab
        }}>
            {children}
        </context.Provider>
    )
}

export default MainContextProvider;
