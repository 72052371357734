import {
	ProfileAvatar,
	NameAvatar,
	ImageGetter,
	AvatarEdit,
	ProfileHeadWrapper,
} from '../../../styles/main/profile'
import {
	useDeleteProfileAvatar,
	useProfileQuery,
	useUpdateProfileAvatar,
} from '../../../core/services/react-query/profile'
import { getRandomColor } from '../../../core/utils/theme'
import { CFlex, DeleteIcon, Flex } from '../../../styles/CommonStyles'
import { useRef } from 'react'
import Tooltip from '../../common/utils/Tooltip'
import { useQueryContext } from '../../../core/contexts/query'
import { BeatLoader } from 'react-spinners'

const ProfileHead = () => {
	const { data: profile, isFetching: fetchingProfile } = useProfileQuery()
	const { mutate: updateAvatar, isLoading: updatingAvatar } = useUpdateProfileAvatar()
	const { mutate: deleteAvatar, isLoading: deletingAvatar } = useDeleteProfileAvatar()

	const avatarRef = useRef()

	const { setToast } = useQueryContext()

	const onUpdateAvatarClicked = () => {
		if (avatarRef?.current?.files[0]?.size > 3500000) {
			setToast({
				isError: true,
				show: true,
				message: 'image-error',
			})
		} else {
			const files = new FormData()
			files.append('avatar', avatarRef?.current?.files[0])
			updateAvatar(files)
		}
	}

	const onDeleteAvatarClicked = () => {
		deleteAvatar()
	}

	const NAMEAVATAR = profile?.firstName ? profile.firstName : profile?.email ? profile?.email : 'U'
	const LOADING_AVATAR = fetchingProfile || updatingAvatar || deletingAvatar
	let NAME = profile?.firstName ? profile.firstName : ''
	NAME += profile?.lastName ? profile.lastName : ''

	return (
		<ProfileHeadWrapper>
			<CFlex>
				<ProfileAvatar size={'big'}>
					{!LOADING_AVATAR ? (
						profile?.avatar ? (
							<img
								style={{ borderRadius: '50%' }}
								width='95%'
								height='95%'
								src={profile.avatar}
								alt={' '}
							/>
						) : (
							<NameAvatar color={getRandomColor(0)} size={'big'}>
								{NAMEAVATAR.toUpperCase().substring(0, 1)}
							</NameAvatar>
						)
					) : (
						<BeatLoader size={11} color='#4f31c5' />
					)}
				</ProfileAvatar>

				<Flex>
					<Tooltip content={'update-avatar'} placement='bottom'>
						<Flex width='42px' height='42px'>
							<AvatarEdit size={22} />
							<ImageGetter
								ref={avatarRef}
								accept='image/jpeg, image/jpg'
								onChange={onUpdateAvatarClicked}
								type='file'
								id='profile-avatar'
								name='profile-avatar'
							/>
						</Flex>
					</Tooltip>

					<Tooltip content={'delete-avatar'} placement='bottom'>
						<Flex
							width='42px'
							height='42px'
							style={{ paddingTop: '4px' }}
							onClick={onDeleteAvatarClicked}
						>
							<DeleteIcon size={24} />
						</Flex>
					</Tooltip>
				</Flex>
			</CFlex>
		</ProfileHeadWrapper>
	)
}

export default ProfileHead
