import { Link } from 'react-router-dom'
import Text from '../../../core/utils/Text'
import styled from 'styled-components'

const CartableLink = (props) => {
    const { _id, tid } = props

    return (
        <CustomLink to={`/cartable/${_id}`}>
            <Text tid={tid} />
        </CustomLink>
    )
}

const CustomLink = styled(Link)`
    color: ${(props) => props.theme.color};
    border-bottom: 1px solid transparent;

    :hover {
        border-bottom: 1px solid ${(props) => props.theme.color};
    }
`

export default CartableLink
