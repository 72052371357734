import ListLayout from "../../../components/layout/main/ListLayout";
import {PageCard} from "../../../styles/CommonStyles";
import FilterLayout from "../../../components/layout/filter/FilterLayout";
import {useState} from "react";
import {CacheKeys, coinTransactionOptions, coinTransactionSortOptions} from "../../../core/constants/filter";
import CoinTransactionTable from "../../../components/main/reports/coin-transactions/CoinTransactionTable";
import {useWindowSize} from "../../../core/hooks/common/useWindowSize";
import {TABLET_SIZE} from "../../../core/constants/common";
import RCoinTransactionTable from "../../../components/responsive/main/reports/coin-transactions/RCoinTransactionTable";
import {useAclContext} from "../../../core/contexts/acl";
import {useGetCoinTransactions} from "../../../core/services/react-query/coining";


const CoinTransaction = () => {

    const { width } = useWindowSize()
    const [transactions, setTransactions] =  useState({data: null, loading: true})

    const {permissions} = useAclContext()
    const hasDownload = permissions?.export?.write

    const onQuerySuccess = (res) => {
        setTransactions(res)
    }

    const Component = width > TABLET_SIZE ? CoinTransactionTable : RCoinTransactionTable

    return (
        <ListLayout>
            <PageCard>
                <FilterLayout
                    query={useGetCoinTransactions}
                    onQuerySuccess={onQuerySuccess}
                    options={coinTransactionOptions}
                    cache={CacheKeys.COIN_TRANSACTIONS}
                    hasDownload={hasDownload}
                    sortOptions={coinTransactionSortOptions}
                    showTotal={{type: 'COIN_TRANSACTIONS'}}
                >
                    {width &&
                        <Component
                            data={transactions}
                        />
                    }
                </FilterLayout>
            </PageCard>
        </ListLayout>
    )
}

export default CoinTransaction;
