import { useMutation, useQuery } from "react-query"
import { useMainContext } from "../../../../contexts/main"
import { makeQueryFilters } from "../../../../utils/query-maker"
import { deleteApi } from "../../../fetch-api/delete"
import { filterFetch, normalFetch } from "../../../fetch-api/get"
import { useQueryContext } from "../../../../contexts/query"


const TRACE_KEYS = {
    GET_WAGES: 'get-wages',
    GET_COIN_TRACE: 'get-coin-trace',
    GET_A_TRACE: 'get-a-trace',
    GET_AFFILIATE_LIST: 'get-affiliate-list',
    GET_AFFILIATE_TRACE: 'get-affiliate-trace',
    GET_AFFILIATE_REPORTS: 'get-affiliate-reports',
    GET_AN_AFFILIATE: 'get-an-affiliate',
    DELETE_AFFILIATE: 'delete-affiliate'
}


const useGetWagesTrace = () => {

    return useQuery(
        TRACE_KEYS.GET_WAGES, () => normalFetch('traces/wages')
    )
}


const useGetCoinTrace = (filters, pagination, extra, csv) => {

    const { lang } = useMainContext()

    const newFilters = {...extra, ...filters}
    const params = makeQueryFilters(newFilters, lang)
    const newParams = {...params, ...pagination}

    if (csv) {
        newParams.export = 'csv'
    }

    return useQuery(
        TRACE_KEYS.GET_COIN_TRACE, () => filterFetch(newParams, 'traces'),
        {
            cacheTime: 0
        }
    )
}


const useGetATrace = (id) => {

    return useQuery(
        TRACE_KEYS.GET_A_TRACE, () => normalFetch(`traces/${id}`),
        {
            select: res => res?.data?.data
        }
    )
}


const useGetAffiliateList = (filters, pagination, extra) => {


   const { lang } = useMainContext()

    const newFilters = {...extra, ...filters}
    const params = makeQueryFilters(newFilters, lang)
    const newParams = {...params, ...pagination}


    return useQuery(
        TRACE_KEYS.GET_AFFILIATE_LIST, () => filterFetch(newParams, 'affiliates/admin')
    )
}


const useGetAnAffiliate = (id) => {

    return useQuery(
        TRACE_KEYS.GET_AN_AFFILIATE, () => normalFetch(`affiliates/admin/${id}`),
        {
            select: res => res?.data?.data
        }
    )
}


const useDeleteAffiliate = () => {

    const { queryClient } = useQueryContext()


    return useMutation(
        TRACE_KEYS.DELETE_AFFILIATE, (id) => deleteApi(`affiliates/admin/${id}`), {
            onSuccess: () => {
                queryClient.invalidateQueries(TRACE_KEYS.GET_AFFILIATE_LIST)
            }
        }
    )
}


const useGetAffiliateReports = (filters, pagination, extra, csv) => {


    const { lang } = useMainContext()

     const newFilters = {...extra, ...filters}
     const params = makeQueryFilters(newFilters, lang)
     const newParams = {...params, ...pagination}

    if (csv) {
        newParams.export = 'csv'
    }

     return useQuery(
         TRACE_KEYS.GET_AFFILIATE_REPORTS, () => filterFetch(newParams, 'traces'),
         {
             cacheTime: 0
         }
     )
 }


const useGetAffiliateTrace = () => {

    return useQuery(
        TRACE_KEYS.GET_AFFILIATE_TRACE, () => normalFetch('traces/affiliates')
    )
}

export {
    useGetWagesTrace,
    useGetCoinTrace,
    useGetATrace,
    useGetAffiliateList,
    useGetAnAffiliate,
    useDeleteAffiliate,
    useGetAffiliateTrace,
    useGetAffiliateReports
}
