import gregorian from "react-date-object/calendars/gregorian";
import gregorian_en from "react-date-object/locales/gregorian_en";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";


export const makeQueryFilters = (filters, lang) => {
    const temp = {query: '', search: ''}
    const keys = Object.keys(filters)
    let search = []
    let query = []

    for (let i = 0; i < keys.length; i++) {

        let key = keys[i]

        let value = filters[key]

        /**
         * special keys
         */
        if (key === 'verifyAt') value = value === 'verified'
        if (key === 'isActive') value = value === 'is-active'

        /**
         * (search - query) or date
         */
        if (key !== 'dateFrom' && key !== 'dateTo') {
            search.push(key)
            query.push(value)
        }else{
            const newValue = value
            newValue.convert(gregorian, gregorian_en)
            value = new Date(newValue.format())
            if (lang === 'fa') newValue.convert(persian, persian_fa)
            value = value.toISOString()
            temp[key] = value
        }
    }
    if (search.length) {
        temp.search = search.join(',')
        temp.query = query.join(',')
    }

    // console.log('queries',temp)
    return temp
}
