import NoData from "../../../../common/utils/NoData";
import {useRemoveUser2fa, useUpdateAUser} from "../../../../../core/services/react-query/user";
import { ReactComponent as RemoveSvg } from "../../../../../assets/illustrations/setting/remove-2fa.svg";
import {CFlex, CMargin, DText} from "../../../../../styles/CommonStyles";
import Text from "../../../../../core/utils/Text";
import ActionButton from "../../../../common/buttons/ActionButton";
import {useEffect, useState} from "react";
import ModalLayout from "../../../../layout/main/ModalLayout";
import BasicModal from "../../../../modals/BasicModal";

export default function RemoveOTP({user, refetch}){
    // console.log(user.settings)
    const hasOTP = !!user?.settings?.twoFactor?.otp || !!user?.settings?.twoFactor?.ga
    // const { mutate: remove2fa, isSuccess, isLoading } = useRemoveUser2fa()
    const { mutate: updateUser, isSuccess: userUpdated, isLoading } = useUpdateAUser()


    const [modal, setModal] = useState(false)
    const onModalClose = () => setModal(false)
    const onModalOpen = () => setModal(true)

    const handleDeleteOTP = () => {
        let newSettings = {...user.settings.twoFactor}
        for (const key in newSettings){
            if(newSettings[key] === true) {
                newSettings[key] = false
            }
        }
        // console.log(user.settings.twoFactor, newSettings)

        // TODO: remove whole OTP process in one click
        const payload = {
            data: {
                settings: { twoFactor: newSettings }
            },
            id: user?._id
        }

        // console.log('payload', payload)
        // console.log('user', user)

        updateUser(payload)
    }

    useEffect(() => {
        if (userUpdated) {
            refetch()
            onModalClose()
        }
    }, [userUpdated])

    return (
        <>
            {hasOTP ?
                <CFlex fw>
                    <CMargin margin={'10px'} />
                    <DText main>
                        <Text tid={'remove-otp'} />
                    </DText>
                    <CFlex width={'300px'}>
                        <RemoveSvg width={'100%'} height={'300px'} />
                        <ActionButton
                            onClick={onModalOpen}
                            height={'42px'}
                            active
                        >
                            <Text tid={'remove-otp'} />
                        </ActionButton>
                    </CFlex>

                </CFlex>
                :
                <NoData desc={'no-otp-active'}/>
            }

            <ModalLayout
                open={modal}
                width={'520px'}
                onClose={onModalClose}
            >
                <BasicModal
                    head={`remove-otp`}
                    _id={user?._id}
                    onClose={onModalClose}
                    onSubmit={handleDeleteOTP}
                    loading={isLoading}
                />
            </ModalLayout>
        </>
    )
}