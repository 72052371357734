import { FaCalendarAlt } from "react-icons/fa";
import Text from "../../../core/utils/Text";
import { getMainTheme } from "../../../core/utils/theme";
import { useMainContext } from "../../../core/contexts/main"
import { IoChevronDownOutline } from "react-icons/io5";
import { useRef, useState } from "react";
import useClickOutside from "../../../core/hooks/common/useClickOutside"
import DatePicker from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
import transition from "react-element-popper/animations/transition"
import "react-multi-date-picker/styles/colors/teal.css"
import "react-multi-date-picker/styles/backgrounds/bg-dark.css"
import { useWindowSize } from "../../../core/hooks/common/useWindowSize";
import { MOBILE_SIZE } from "../../../core/constants/common";


const AnalysisDateSelect = ({ value, onChange, options, customDates, onCustomChange, monthlyDate, onMonthlyChange }) => {

    const { width } = useWindowSize()
    const { lang, theme } = useMainContext()
    const [open, setOpen] = useState(false)
    const ref = useRef()
    useClickOutside(ref, () => setOpen(false))

    const onDateChange = (v, _value) => {
        if (_value === 'monthly') {
            if (!!v) {
                onMonthlyChange(v)
                setOpen(false)
                onChange('monthly')
            }
            return
        }
        if (_value === 'custom') {
            if (v?.length > 1) {
                onCustomChange(v)
                setOpen(false)
                onChange('custom')
            }
            return
        }
        onChange(v)
    }

    return (
        <div className={'relative'} ref={ref}>
            <div className={`
                    rounded dark:bg-mainBg border-[1px] border-slate-500
                    border-opacity-30 px-3 py-[6px] text-sm font-semibold
                    flex items-center justify-between gap-2 w-max dark:text-slate-300
                    text-slate-600 min-w-[144px] cursor-pointer
                `}
                onClick={() => setOpen(state => !state)}
            >
                <div className={'flex items-center gap-2'}>
                    <FaCalendarAlt size={18} color={getMainTheme(theme).color} />
                    <Text className={'mt-1'} tid={value || 'select-date'} />
                </div>
                <IoChevronDownOutline size={18} color={getMainTheme(theme).color} />
            </div>

            {open ?
                <div className={`absolute min-w-[200px] text-sm
                    border-[1px] border-slate-500 py-2 px-2 rounded shadow
                    border-opacity-30 z-[1000] dark:bg-mainBg bg-white
                `}>
                    {options?.map(item => {

                        if (item === 'monthly') {
                            return (
                                <DatePicker
                                    calendar={lang === 'fa' && persian}
                                    locale={lang === 'fa' && persian_fa}
                                    value={monthlyDate}
                                    onChange={(v) => onDateChange(v, 'monthly')}
                                    animations={[transition()]}
                                    render={<DateInput tid={'monthly'} />}
                                    onlyMonthPicker
                                    className={`${theme === 'dark' && 'bg-dark custom-calendar'} teal ${width < MOBILE_SIZE && 'rmdp-mobile'}`}
                                    calendarPosition={lang === 'fa' ? 'left-top' : 'right-top'}
                                />
                            )
                        }

                        if (item === 'custom') {
                            return (
                                <DatePicker
                                    calendar={lang === 'fa' && persian}
                                    locale={lang === 'fa' && persian_fa}
                                    value={customDates}
                                    onChange={(v) => onDateChange(v, 'custom')}
                                    animations={[transition()]}
                                    render={<DateInput tid={'custom'} />}
                                    className={`${theme === 'dark' && 'bg-dark custom-calendar'} teal ${width < MOBILE_SIZE && 'rmdp-mobile'}`}
                                    calendarPosition={lang === 'fa' ? 'left-top' : 'right-top'}
                                    range
                                />
                            )
                        }

                        return (
                            <div
                                key={item}
                                className={`cursor-pointer py-1 px-3 rounded 
                                    dark:text-gray-300 hover:bg-slate-500 hover:bg-opacity-50`}
                                onClick={() => {
                                    onDateChange(item)
                                    setOpen(false)
                                }}
                            >
                                <Text tid={item} />
                            </div>
                        )
                    })}
                </div>
                : null}
        </div>
    )
}


const DateInput = ({ openCalendar, handleValueChange, tid, ...rest }) => {

    return (
        <div
            key={tid}
            onClick={openCalendar}
            className={`cursor-pointer py-1 px-3 rounded 
        dark:text-gray-300 hover:bg-slate-500 hover:bg-opacity-50`}
            {...rest}
        >
            <Text tid={tid} />
        </div>
    )
}

export default AnalysisDateSelect