import { useMemo, useState } from "react"
import { useGetCoinAnalysisHistory, useGetFiatAnalysisHistory } from "../../../core/services/react-query/analysis"
import { ClipLoader } from "react-spinners"
import { getMainTheme } from "../../../core/utils/theme"
import EChart from "../../layout/EChart"
import { useMainContext } from "../../../core/contexts/main"
import AnalysisDateSelect from "./AnalysisDateSelect"
import CoinSelect from "../../common/input/CoinSelect"

const dateOptions = ['monthly', 'custom']

const TransactionsHistoryChart = ({ flow }) => {

    const { theme, lang } = useMainContext()
    const [date, setDate] = useState('monthly')
    const [monthlyDate, setMonthlyDate] = useState()
    const [customDates, setCustomDates] = useState([])
    const [coin, setCoin] = useState({ id: 'irt', name: 'Toman', fa: 'تومان' })

    const params = useMemo(() => {

        if (date === 'monthly' && !!monthlyDate) {

            if (lang === 'fa') {

                try {
                    const dateInLocale = new Date(monthlyDate).toLocaleDateString('fa-IR-u-nu-latn')
                    const splittedDateInLocale = dateInLocale.split('/')
                    const daysToAdd = parseInt(splittedDateInLocale[1]) > 6 ? 30 : 31
                    let from = new Date(monthlyDate)
                    let to = new Date(monthlyDate)
                    to.setDate(to.getDate() + daysToAdd)
                    return { dateFrom: from.toISOString(), dateTo: to.toISOString() }
                } catch (_) { }

            } else {
                // TODO: english dates
            }
        }

        if (date === 'custom' && customDates?.length > 1) {
            return { dateFrom: (new Date(customDates[0])).toISOString(), dateTo: (new Date(customDates[1])).toISOString() }
        }

    }, [date, customDates, monthlyDate])

    const { data: fiatData, isLoading: fiatDataLoading } = useGetFiatAnalysisHistory({ params, flow })
    const { data: coinData, isLoading: coinDataLoading } = useGetCoinAnalysisHistory({ params, flow, coin: coin.id })

    const extractValues = (list, key) => {
        const temp = []
        for (let i = 0; i < list?.length; i++) {
            let value = list[i][key]
            if (key === 'date') {
                if (lang === 'fa') {
                    value = new Date(value).toLocaleDateString('fa-IR-u-nu-latn')
                }
            }
            temp.push(value)
        }
        return temp
    }

    console.log({ fiatData, coinData })

    const options = useMemo(() => {

        if (!coinData?.length && !fiatData?.length) return {}

        return {
            color: [
                '#1a8bab',
            ],
            xAxis: {
                type: 'category',
                data: extractValues(coin.id === 'irt' ? fiatData : coinData, 'date')
            },
            yAxis: {
                type: 'value',
                splitLine: {
                    lineStyle: {
                        color: theme === 'dark' ? '#c3c5b720' : '#00000020',
                        type: 'dashed'
                    }
                }
            },
            tooltip: {
            },
            series: [
                {
                    data: extractValues(coin.id === 'irt' ? fiatData : coinData, 'amount'),
                    type: 'bar',
                }
            ]
        };
    }, [fiatData, coinData, coin])

    return (
        <div className={'dark:text-gray-200 text-sm'}>
            <div className={`
            rounded-md bg-gray-100 dark:bg-mainBg py-3 px-1 shadow-md
            border-[1px] border-slate-500 border-opacity-30 relative h-[420px]
                `}>
                {(coinDataLoading || fiatDataLoading) ?
                    <div className={'absolute left-0 top-0 w-full h-full flex items-center justify-center'}>
                        <ClipLoader color={getMainTheme().active} size={20} />
                    </div>
                    :
                    null
                }

                <div className={'flex items-center gap-5'}>
                    <AnalysisDateSelect
                        value={date}
                        onChange={setDate}
                        options={dateOptions}
                        customDates={customDates}
                        onCustomChange={setCustomDates}
                        monthlyDate={monthlyDate}
                        onMonthlyChange={setMonthlyDate}
                    />
                    <CoinSelect
                        value={coin}
                        onChange={setCoin}
                        hasIrt
                    />
                </div>

                <EChart option={options} style={{ width: '100%', height: '360px' }} dir={'ltr'} />

            </div>
        </div>
    )
}

export default TransactionsHistoryChart