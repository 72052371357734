import styled from "styled-components";
import { formatNumber } from "../../../core/utils/common";
import Text from "../../../core/utils/Text";
import { FiltersOn } from "../../../styles/layout/filter";
import Tooltip from "../../common/utils/Tooltip";

export default function TotalAmount({total, mb = 0}) {
    return (
        <Wrapper mb={mb}>
            <Tooltip content={'total-shown-amount'} placement='top' width='400px' whiteSpace='wrap' dir='rtl'>
                <FiltersOn color='#56ffb8' />
            </Tooltip>
            <Text tid='total-amount' />
            <span>{formatNumber(total)}</span>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    color: ${props => props.theme.color};
    margin-inline-start: 1rem;
    margin-bottom: ${props => props.mb};
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    column-gap: 5px;

    span {
        font-size: 1rem;
    }

    @media screen and (max-width: 768px) {
        font-size: 0.6rem;

        span {
            font-size: 0.8rem;
        }
    }
`