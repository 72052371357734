import { userNotificationsAttributes } from '../../../../core/constants/headers'
import useGetTableIndex from '../../../../core/hooks/layout/useGetTableIndex'
import Text from '../../../../core/utils/Text'
import { Column, DText, Relative, Row } from '../../../../styles/CommonStyles'
import DateTime from '../../../common/utils/DateTime'
import TableLayout from '../../../layout/main/TableLayout'

export default function NotificationTable({ data }) {
    const { data: notifications } = data
    const { cs, headers } = userNotificationsAttributes
    const { getTableIndex } = useGetTableIndex()

    return (
        <TableLayout headers={headers} cs={cs} data={data}>
            {notifications?.data?.map((item, index) => {
                console.log(item)
                return (
                    <Relative>
                        <Row cs={cs} index={index} key={item._id}>
                            <Column>{getTableIndex(index)}</Column>
                            <Column>
                                <DText fontSize='s'>
                                    {item.title?.trim() || (
                                        <Text tid='no-title' />
                                    )}
                                </DText>
                            </Column>
                            <Column>
                                <DText fontSize='ss'>
                                    {item.body?.trim() || (
                                        <Text tid='no-description' />
                                    )}
                                </DText>
                            </Column>
                            <Column>
                                <DText fontSize='ss'>
                                    <Text tid={item.type} />
                                </DText>
                            </Column>
                            <Column
                                style={{
                                    color:
                                        item.priority === 'HIGH'
                                            ? '#1ce087'
                                            : item.priority === 'MEDIUM'
                                            ? '#42958D'
                                            : '#a12356',
                                }}>
                                <DText fontSize='ss'>
                                    <Text tid={item.priority} />
                                </DText>
                            </Column>
                            <Column>
                                <DateTime dt={item.createdAt} />
                            </Column>
                            <Column>
                                {item?.readAt ? (
                                    <DateTime dt={item.readAt} />
                                ) : (
                                    <Text tid='unread' />
                                )}
                            </Column>
                        </Row>
                    </Relative>
                )
            })}
        </TableLayout>
    )
}
