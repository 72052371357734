import { useState } from "react"
import ListLayout from "../../components/layout/main/ListLayout"
import AllBalances from "../../components/main/analysis/AllBalances"
import { useSearchParams } from "react-router-dom";
import Text from "../../core/utils/Text";
import TransactionBalances from "../../components/main/analysis/TransactionBalances";
import TransactionsHistoryChart from "../../components/main/analysis/TransactionsHistoryChart";

const tabs = ['deposit', 'withdraw']

const Analysis = () => {

    const [searchParams, setSearchParams] = useSearchParams()
    const [activeTab, setActiveTab] = useState(searchParams.get('tab') || 'deposit')
    const onTabChange = (tab) => {
        setActiveTab(tab)
        setSearchParams({ tab: tab })
    }

    return (
        <ListLayout>
            <div className={'flex flex-col gap-5 w-full'}>

                {/* Tabs */}
                <div className={'flex items-center justify-center gap-5'}>
                    {tabs.map(item => {

                        const active = activeTab === item
                        return (
                            <div
                                key={item}
                                className={
                                    `${active ? 'bg-active text-slate-800 dark:text-slate-800' : ''}
                                    rounded border-[1px] border-slate-500 border-opacity-30
                                    px-12 py-2 font-semibold cursor-pointer dark:text-gray-200
                                    text-sm transition`}
                                onClick={() => onTabChange(item)}
                            >
                                <Text tid={item} />
                            </div>
                        )
                    })}
                </div>

                {/* Deposit/Withdraw Balances */}
                <TransactionBalances
                    flow={activeTab}
                />

                <TransactionsHistoryChart
                    flow={activeTab}
                />

                {/* User Balances */}
                <div className={"grid grid-cols-2 w-full"}>
                    <AllBalances />
                </div>
            </div>
        </ListLayout>
    )
}

export default Analysis
