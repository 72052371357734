

export const acceptUserOperations = [
    'accept-user',
    'increase-balance',
    'decrease-balance'
]

export const rejectUserOperations = [
    'reject-user',
    'increase-balance',
    'decrease-balance'
]


export const transactionOperations = [
    'approve',
    'reject',
    'rollback'
]

export const rejectFiatTransactionOperation = [
    'reject-transaction'
]

export const availableTokenOperations = [
    'edit', 'delete', 'delete-from-available-coins'
]

export const notAvailableTokenOperations = [
    'edit', 'delete', 'add-to-available-coins'
]


export const bankAccountOperations = [
    'accept-bank', 'reject-bank'
]

export const balanceSignatureOperations = [
    'accept-action',
    'reject-action',
    'delete-signature'
]
