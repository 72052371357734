import { useGetUserBalancesCoin, useGetUserBalancesFiat } from "../../../core/services/react-query/analysis"
import Text from "../../../core/utils/Text"
import { ClipLoader } from "react-spinners";
import { getMainTheme } from "../../../core/utils/theme";
import { SOCKET_URL } from "../../../core/constants/urls";
import { deepCopy, formatNumber } from "../../../core/utils/common";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import CheckBox from "../../common/input/CheckBox";

const AllBalances = () => {

    const { t } = useTranslation()

    const [excludeId, setExcludeId] = useState('')
    const [search, setSearch] = useState('')
    const [isExclude, setIsExclude] = useState(false)

    const { data: coinData, isLoading: coinDataLoading, refetch: refetchCoins } =
        useGetUserBalancesCoin({ excludeId, isExclude })
    const { data: fiatData, isLoading: fiatDataLoading, refetch: refetchFiat } =
        useGetUserBalancesFiat({ excludeId, isExclude })

    useEffect(() => {
        if (isExclude && excludeId?.length === 24) {
            refetchCoins()
            refetchFiat()
        }
        if (!isExclude && !excludeId) {
            refetchCoins()
            refetchFiat()
        }
    }, [isExclude, excludeId])

    const onBoardCoins = useMemo(() => {

        let temp = deepCopy(coinData)

        if (!!search) {
            temp = temp.filter(x => x.coin.includes(search?.toLowerCase()))
        }

        return temp
    }, [search, coinData])

    return (
        <div className={'flex flex-col gap-2 dark:text-gray-200 text-sm'}>
            <Text tid={'all-balances'} className={'text-base'} />
            <div className={'flex items-center gap-3 py-2'}>
                <CheckBox
                    active={isExclude}
                    onClick={() => setIsExclude(state => !state)}
                />
                <div className={`dark:bg-slate-800 bg-slate-200 px-5
                        border-[1px] border-slate-500 border-opacity-50 w-[80%] h-[48px]
                        flex items-center rounded-md
                        `}>
                    <input
                        value={excludeId}
                        onChange={e => setExcludeId(e?.target?.value)}
                        className={'bg-transparent text-sm w-full h-full  focus:border-none focus:outline-none'}
                        placeholder={t('exclude-user-id')}
                    />
                </div>
            </div>
            <div className={`
            rounded-md bg-gray-100 dark:bg-mainBg py-3 px-1 shadow-md
            border-[1px] border-slate-500 border-opacity-30 relative h-[400px]
                `}>
                {(coinDataLoading || fiatDataLoading) ?
                    <div className={'absolute left-0 top-0 w-full h-full flex items-center justify-center'}>
                        <ClipLoader color={getMainTheme().active} size={20} />
                    </div>
                    :
                    null
                }

                <div className={'flex items-center gap-2 py-2'}>
                    <div className={`dark:bg-slate-800 bg-slate-200 px-5 mx-5
                        border-[1px] border-slate-500 border-opacity-50 w-[90%] h-[48px]
                        flex items-center rounded-md
                        `}>
                        <input
                            value={search}
                            onChange={e => setSearch(e?.target?.value)}
                            className={'bg-transparent text-sm w-full h-full  focus:border-none focus:outline-none'}
                            placeholder={t('search-coins')}
                        />
                    </div>
                </div>

                <div className={'grid grid-cols-8 px-5 py-2 border-b-[1px] border-slate-500 border-opacity-30 text-gray-400'}>
                    <div className={'col-span-3'}>
                        <Text tid={'currency'} />
                    </div>
                    <div className={'col-span-2'}>
                        <Text tid={'amount'} />
                    </div>
                    <div className={'col-span-2'}>
                        <Text tid={'amountBlocked'} />
                    </div>
                    <Text tid={'count'} />
                </div>

                <div className={'flex flex-col gap-3 overflow-y-auto h-[280px] px-3 py-1'}>

                    {fiatData?.length ?
                        <div className={'grid grid-cols-8 items-center p-2 hover:bg-slate-500 hover:bg-opacity-50 rounded'}>
                            <div className={'flex items-center gap-2 col-span-3'}>
                                <img
                                    src={require('../../../assets/images/tooman.png')}
                                    alt={' '}
                                    width={30}
                                />
                                <span className={'mt-2'}>IRT</span>
                            </div>
                            <div className={'col-span-2'}>
                                <span className={'mt-2'}>{formatNumber(fiatData[0]?.balance, { type: 'irt' })}</span>
                            </div>
                            <div className={'col-span-2'}>
                                <span className={'mt-2'}>{formatNumber(fiatData[0]?.balanceBlocked, { type: 'irt' })}</span>
                            </div>
                            <span className={'mt-2'}>{formatNumber(fiatData[0]?.count, { type: 'irt' })}</span>
                        </div>
                        : null}


                    {onBoardCoins?.map(coin => {

                        return (
                            <div key={coin.coin} className={'grid grid-cols-8 items-center p-2 hover:bg-slate-500 hover:bg-opacity-50 rounded'}>
                                <div className={'flex items-center gap-2 col-span-3'}>
                                    <img
                                        src={SOCKET_URL + `assets/icon/${coin.coin}.png`}
                                        alt={' '}
                                        width={30}
                                    />
                                    <span className={'mt-2'}>{coin.coin?.toUpperCase()}</span>
                                </div>
                                <div className={'col-span-2'}>
                                    <span className={'mt-2'}>{formatNumber(coin.amount, { type: coin.coin })}</span>
                                </div>
                                <div className={'col-span-2'}>
                                    <span className={'mt-2'}>{formatNumber(coin.amountBlocked, { type: coin.coin })}</span>
                                </div>
                                <span className={'mt-2'}>{formatNumber(coin.count, { type: 'irt' })}</span>
                            </div>
                        )
                    })}
                </div>


            </div>
        </div>

    )
}

export default AllBalances
