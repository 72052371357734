import {useMutation, useQuery} from "react-query";
import {postApi} from "../../fetch-api/post";
import {putApi} from "../../fetch-api/put";
import {useQueryContext} from "../../../contexts/query";
import {filterFetch, normalFetch} from "../../fetch-api/get";
import {useMainContext} from "../../../contexts/main";
import {makeQueryFilters} from "../../../utils/query-maker";
import {deleteApi} from "../../fetch-api/delete";


const MULTI_SIGNATURE_KEYS = {
    FETCH_MULTI_SIGNATURES: 'fetch-multi-signatures',
    GET_A_MULTI_SIGNATURE: 'get-a-multi-signature',
    CREATE_MULTI_SIGNATURE: 'create-multi-signature',
    UPLOAD_MULTI_SIGNATURE_IMAGE: 'upload-multi-signature-image',
    UPDATE_MULTI_SIGNATURE: 'update-multi-signature',
    DELETE_MULTI_SIGNATURE: 'delete-multi-signature'
}


const useGetAllMultiSignatures = (filters, pagination) => {
    // ** modified cartable filters w/ Name option 
    const modifiedFilters = {...filters}
    // console.log(modifiedFilters)
    if(filters.firstName || filters.lastName) {
        modifiedFilters['askByUser.firstName'] = filters.firstName
        modifiedFilters['askByUser.lastName'] = filters.lastName

        delete modifiedFilters.firstName
        delete modifiedFilters.lastName
    }
    // console.log(modifiedFilters)

    const { lang } = useMainContext()
    const params = {...makeQueryFilters(modifiedFilters, lang), ...pagination}

    return useQuery(
        MULTI_SIGNATURE_KEYS.FETCH_MULTI_SIGNATURES, () => filterFetch(params, 'multiSignatures')
    )
}


const useGetAMultiSignature = (id) => {

    return useQuery(
        [MULTI_SIGNATURE_KEYS.GET_A_MULTI_SIGNATURE, id], () => normalFetch(`multiSignatures/${id}`),
        {
            select: res => res?.data?.data
        }
    )
}


const useCreateMultiSignature = () => {

    const { setToast } = useQueryContext()

    return useMutation(
        MULTI_SIGNATURE_KEYS.CREATE_MULTI_SIGNATURE, (data) => postApi(data, 'multiSignatures'),
        {
            onSuccess: () => {
                setToast({
                    show: true,
                    message: 'operation-success'
                })
            }
        }
    )
}


const useUpdateMultiSignature = () => {

    const { queryClient, setToast } = useQueryContext()

    return useMutation(
        MULTI_SIGNATURE_KEYS.UPDATE_MULTI_SIGNATURE, (payload) => putApi(payload.data, `multiSignatures/${payload.id}`),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([MULTI_SIGNATURE_KEYS.FETCH_MULTI_SIGNATURES])
                queryClient.invalidateQueries([MULTI_SIGNATURE_KEYS.GET_A_MULTI_SIGNATURE])
                setToast({
                    message: 'operation-success',
                    show: true
                })
            }
        }
    )
}

const useDeleteMultiSignature = (onSuccess = () => {}) => {

    const { queryClient, setToast } = useQueryContext()

    return useMutation(
        MULTI_SIGNATURE_KEYS.DELETE_MULTI_SIGNATURE, (id) => deleteApi(`multiSignatures/${id}`),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([MULTI_SIGNATURE_KEYS.FETCH_MULTI_SIGNATURES])
                setToast({
                    message: 'operation-success',
                    show: true
                })
                onSuccess()
            }
        }
    )
}


const useUploadMultiSignatureImages = () => {

    return useMutation(
        MULTI_SIGNATURE_KEYS.UPLOAD_MULTI_SIGNATURE_IMAGE, (data) => postApi(data, 'multiSignatures/upload')
    )
}





export {
    useGetAllMultiSignatures,
    useCreateMultiSignature,
    useUpdateMultiSignature,
    useUploadMultiSignatureImages,
    useDeleteMultiSignature,
    useGetAMultiSignature
}
